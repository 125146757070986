import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/introduction/introduction.module').then(
        (m) => m.IntroductionPageModule
      ),
  },
  {
    path: 'treks',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/treks/treks.module').then((m) => m.TreksPageModule),
      },
      {
        path: ':trekId',
        loadChildren: () =>
          import('./pages/trek-details/trek-details.module').then(
            (m) => m.TrekDetailsPageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload',
      preloadingStrategy: NoPreloading,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
