import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Preferences } from '@capacitor/preferences';
import {
  Trek,
  Filter,
  Settings,
  DataSetting,
  HydratedTrek,
  Property,
  InformationDesk,
  TouristicContents,
  TouristicCategorie,
  TouristicCategoryWithFeatures,
  TouristicEvents,
  TouristicEventsCategoryWithFeatures,
  Order,
  Pois,
} from '@app/interfaces/interfaces';
import { environment } from '@env/environment';
import { Platform } from '@ionic/angular';
import { GeoJSON } from 'geojson';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private apiUrl = `${environment.onlineBaseUrl}`;
  public settings: any = undefined;
  public data$ = new BehaviorSubject<DataSetting[] | null>(null);
  public filters$ = new BehaviorSubject<Filter[] | null>(null);
  public order$ = new BehaviorSubject<{
    type: Order;
    value: number[] | undefined;
  } | null>({ type: 'alphabetical', value: undefined });
  public offlineMode$ = new BehaviorSubject<boolean | null>(false);
  public userLocation$ = new BehaviorSubject<number[]>([0, 0]);
  public language: string | undefined;

  constructor(
    public http: HttpClient,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.language = this.getLanguage();
  }

  public initializeApp(): Promise<any> {
    return new Promise(async (resolve) => {
      this.translate.use(this.getLanguage());
      await this.loadSettings();
      resolve(true);
    });
  }

  public getLanguage(): string {
    let defaultLanguage: string;

    if (isPlatformBrowser(this.platformId)) {
      defaultLanguage = navigator.language.slice(0, 2);
    }
    if (environment.availableLanguage.indexOf(defaultLanguage) === -1) {
      defaultLanguage = environment.availableLanguage[0];
    }

    return defaultLanguage;
  }

  public loadSettings(): Promise<any> {
    return new Promise((resolve) => {
      this.getSettings().subscribe(
        async (settings) => {
          this.data$.subscribe((data) => {
            if (data) {
              resolve(true);
            }
          });

          if (isPlatformBrowser(this.platformId)) {
            await Preferences.set({
              key: 'settings',
              value: JSON.stringify(settings),
            });
          }
          this.settings = settings;
          this.data$.next(settings.data);
        },
        async () => {
          if (isPlatformBrowser(this.platformId)) {
            this.data$.subscribe((data) => {
              if (data) {
                resolve(true);
              }
            });
            const { value } = await Preferences.get({ key: 'settings' });
            const defaultSettings = JSON.parse(value);
            if (defaultSettings) {
              this.settings = defaultSettings;
              this.data$.next(defaultSettings.data);
            }
          }
        }
      );
    });
  }

  public getFilters() {
    const filters: Filter[] = [];
    if (this.settings && this.settings.filters && this.settings.data) {
      this.settings.filters.forEach((filter) => {
        const currentDataSetting = this.settings.data.find(
          (data: DataSetting) => data.id === filter.id
        );
        if (currentDataSetting) {
          filter = { ...filter, ...currentDataSetting, values: [] };
          filter.values = currentDataSetting.values.map((value) => ({
            ...value,
            checked: false,
          }));
          filters.push(filter);
        }
      });
    }
    this.filters$.next(filters);

    return filters;
  }

  public getSettings(): Observable<Settings> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': this.translate.getDefaultLang(),
      }),
    };
    return this.http.get<Settings>(this.apiUrl + '/settings.json', httpOptions);
  }

  public getWaysFromUrl(): Observable<GeoJSON> {
    return this.http.get<GeoJSON>(this.apiUrl + '/routes.geojson');
  }

  public setWaysOnStorage(ways: any): any {
    return Preferences.set({ key: 'ways', value: JSON.stringify(ways) });
  }

  public async getWaysFromStorage() {
    const { value } = await Preferences.get({ key: 'ways' });
    const zone = JSON.parse(value);
    return zone
      ? zone
      : {
          type: 'FeatureCollection',
          features: [],
        };
  }

  public saveFiltersState(filters: Filter[]): void {
    this.filters$.next(filters);
  }

  public saveOrderState(order: Order, geolocation?: number[]): void {
    this.order$.next({ type: order, value: geolocation });
  }

  public saveOfflineMode(offlineMode: boolean): void {
    this.offlineMode$.next(offlineMode);
  }

  public resetFilters(): void {
    let filters = [];
    const filtersValue = this.filters$.getValue();
    if (!!filtersValue) {
      filtersValue.forEach((filter) => {
        filters.push({
          ...filter,
          values: { ...filter.values, checked: false },
        });
      });
    }
    this.saveFiltersState(filters);
  }

  public getValueForPropertyById(
    propertyName: string,
    id: number | string
  ): Property | InformationDesk | TouristicCategorie | string | undefined {
    if (this.data$) {
      const dataSetting = this.data$.getValue();

      if (dataSetting && Array.isArray(dataSetting)) {
        const property = dataSetting.find(
          (data) => data && data.id && data.id === propertyName
        );
        if (property && property.values && Array.isArray(property.values)) {
          const propertyValue = property.values.find(
            (value) => value && value.id && value.id === id
          );
          if (propertyValue) {
            return propertyValue;
          }
        }
      }
    }

    return undefined;
  }

  public getHydratedTrek(trek: Trek): HydratedTrek {
    const hydratedTrek: HydratedTrek = {
      ...trek,
      properties: {
        ...trek.properties,
      },
    } as any;

    if (trek.properties.difficulty) {
      hydratedTrek.properties.difficulty = this.getValueForPropertyById(
        'difficulty',
        trek.properties.difficulty
      ) as Property;
    }

    if (trek.properties.practice) {
      hydratedTrek.properties.practice = this.getValueForPropertyById(
        'practice',
        trek.properties.practice
      ) as Property;
    }

    if (trek.properties.route) {
      hydratedTrek.properties.route = this.getValueForPropertyById(
        'route',
        trek.properties.route
      ) as Property;
    }

    if (trek.properties.departure_city) {
      hydratedTrek.properties.departure_city = this.getValueForPropertyById(
        'cities',
        trek.properties.departure_city
      ) as Property;
    }

    if (trek.properties.arrival_city) {
      hydratedTrek.properties.arrival_city = this.getValueForPropertyById(
        'cities',
        trek.properties.arrival_city
      ) as Property;
    }

    // if (trek.properties.networks) {
    //   hydratedTrek.properties.networks = trek.properties.networks.map(
    //     (network) =>
    //       this.getValueForPropertyById('networks', network) as Property
    //   );
    // }

    if (trek.properties.themes) {
      hydratedTrek.properties.themes = trek.properties.themes.map(
        (theme) => this.getValueForPropertyById('themes', theme) as Property
      );
    }

    if (trek.properties.information_desks) {
      hydratedTrek.properties.information_desks =
        trek.properties.information_desks.map((information_desk) => ({
          ...information_desk,
          type: this.getValueForPropertyById(
            'information_desk_types',
            information_desk.type as number
          ) as Property,
        }));
    }

    return hydratedTrek;
  }

  public getPoisTypesWithFeatures(pois: Pois): any {
    const poisTypesWithFeatures: any[] = [];
    if (pois && Array.isArray(pois.features)) {
      const types = pois.features
        .map((poi) => poi.properties.type)
        .filter((v, i, a) => a.indexOf(v) === i);
      types.forEach((typeId) => {
        const type = this.getValueForPropertyById(
          'poi_types',
          typeId
        ) as TouristicCategorie;
        poisTypesWithFeatures.push({
          id: typeId,
          name: type ? type.name : '',
          features: pois.features.filter(
            (poi) => poi.properties.type === typeId
          ),
        });
      });
    }

    return poisTypesWithFeatures;
  }

  public getTouristicCategoriesWithFeatures(
    touristicContents: TouristicContents
  ): TouristicCategoryWithFeatures[] {
    const touristicCategoriesWithFeatures: TouristicCategoryWithFeatures[] = [];
    if (touristicContents && Array.isArray(touristicContents.features)) {
      const categories = touristicContents.features
        .map((touristicContent) => touristicContent.properties.category)
        .filter((v, i, a) => a.indexOf(v) === i);
      categories.forEach((categoryId) => {
        const category = this.getValueForPropertyById(
          'touristiccontent_categories',
          categoryId
        ) as TouristicCategorie;
        touristicCategoriesWithFeatures.push({
          id: categoryId,
          name: category ? category.name : '',
          features: touristicContents.features.filter(
            (touristicContent) =>
              touristicContent.properties.category === categoryId
          ),
        });
      });
    }

    return touristicCategoriesWithFeatures;
  }

  public getTouristicEventsCategoriesWithFeatures(
    touristicEvents: TouristicEvents
  ): TouristicEventsCategoryWithFeatures[] {
    const touristicEventsCategoriesWithFeatures: TouristicEventsCategoryWithFeatures[] =
      [];
    if (touristicEvents && Array.isArray(touristicEvents.features)) {
      const types = touristicEvents.features
        .map((touristicEvent) => touristicEvent.properties.type)
        .filter((v, i, a) => a.indexOf(v) === i);
      types.forEach((typeId) => {
        const type = this.getValueForPropertyById(
          'touristicevent_types',
          typeId
        ) as TouristicCategorie;
        touristicEventsCategoriesWithFeatures.push({
          id: typeId,
          name: type ? type.name : '',
          features: touristicEvents.features.filter(
            (touristicEvents) => touristicEvents.properties.type === typeId
          ),
        });
      });
    }

    return touristicEventsCategoriesWithFeatures;
  }

  public getServicesCategoriesWithFeatures(services: any): any[] {
    const servicesCategoriesWithFeatures: any[] = [];
    if (services && Array.isArray(services.features)) {
      const types = services.features
        .map((service) => service.properties.service_types)
        .filter((v, i, a) => a.indexOf(v) === i);
      types.forEach((typeId) => {
        const type = this.getValueForPropertyById(
          'service_types',
          typeId
        ) as any;
        servicesCategoriesWithFeatures.push({
          id: typeId,
          name: type ? type.name : '',
          features: services.features.filter(
            (service) => service.properties.service_types === typeId
          ),
        });
      });
      return servicesCategoriesWithFeatures;
    }
  }

  public getInfrastructuresCategoriesWithFeatures(infrastructures: any): any[] {
    const infrastructuresCategoriesWithFeatures: any[] = [];
    if (infrastructures && Array.isArray(infrastructures.features)) {
      const types = infrastructures.features
        .map((infrastructure) => infrastructure.properties.infrastructure_types)
        .filter((v, i, a) => a.indexOf(v) === i);
      types.forEach((typeId) => {
        const type = this.getValueForPropertyById(
          'infrastructure_types',
          typeId
        ) as any;
        infrastructuresCategoriesWithFeatures.push({
          id: typeId,
          name: type ? type.name : '',
          features: infrastructures.features.filter(
            (infrastructure) =>
              infrastructure.properties.infrastructure_types === typeId
          ),
        });
      });
      return infrastructuresCategoriesWithFeatures;
    }
  }
}
