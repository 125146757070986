import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private updates: SwUpdate,
    public alertController: AlertController
  ) {}

  ngOnInit() {
    this.updates.available.subscribe(() => {
      this.updates.activateUpdate().then(() => {
        this.presentReloadToInstallUpdateAlert();
      });
    });
  }

  async presentReloadToInstallUpdateAlert() {
    const reloadToInstallUpdateAlert = await this.alertController.create({
      header: 'Une mise à jour est disponible',
      message:
        "L'application va se recharger afin d'installer la nouvelle version",
      buttons: ["J'ai compris"],
    });

    await reloadToInstallUpdateAlert.present();

    await reloadToInstallUpdateAlert.onDidDismiss();

    document.location.reload();
  }
}
