import { primaryColor } from '@env/variables';

export const environment = {
  production: true,
  useMatomo: true,
  appName: 'GeoCompostelle',
  availableLanguage: ['fr'],
  onlineBaseUrl: 'https://geocompostelle.makina-corpus.net/api/mobile',
  onlineMapConfig: {
    attributionControl: false,
    style: 'assets/map/web-style-online.json',
    minZoom: 0,
    maxZoom: 20,
  },
  offlineMapConfig: {
    attributionControl: false,
    style: 'assets/map/web-style-offline.json',
    minZoom: 0,
    maxZoom: 20,
  },
  trekZoom: {
    minZoom: 0,
    maxZoom: 20,
    zoom: 13,
  },
  map: {
    treksfitBoundsOptions: { padding: 50, animate: false },
    trekfitBoundsOptions: { padding: 50, animate: false },
    globalMapIconSize: 1,
    informationIconSize: 1,
    parkingIconSize: 1,
    departureArrivalIconSize: 1,
    poisLayersProperties: {
      visibility: 'visible',
      iconSize: 1,
    },
    clusterPaint: {
      'circle-color': primaryColor,
      'circle-stroke-color': '#fff',
      'circle-radius': 18,
      'circle-stroke-width': 1,
    },
    clusterTextPaint: {
      'text-color': '#fff',
    },
    waysOutlineLayerProperties: {
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['has', 'type'],
          [
            'case',
            ['==', ['get', 'type'], 'Voie principale'],
            '#C62C1C',
            ['==', ['get', 'type'], 'Voie alternative'],
            '#C62C1C',
            ['==', ['get', 'type'], 'Voie secondaire'],
            '#C62C1C',
            '#e58a5e',
          ],
          '#e58a5e',
        ],
        'line-width': [
          'case',
          ['has', 'type'],
          [
            'case',
            ['==', ['get', 'type'], 'Voie principale'],
            4,
            ['==', ['get', 'type'], 'Voie alternative'],
            4,
            ['==', ['get', 'type'], 'Voie secondaire'],
            4,
            4,
          ],
          4,
        ],
      },
    },
    trekLineLayerProperties: {
      type: 'line',
      paint: {
        'line-width': 6,
        'line-color': '#61B22F',
      },
    },
    pointReferenceLayersProperties: {
      text: {
        paint: {
          'text-color': '#fff',
        },
        layout: {
          'text-ignore-placement': true,
          'text-allow-overlap': true,
          'text-field': ['get', 'index'],
          'text-font': ['Roboto Regular'],
          'text-size': 12,
          'text-offset': [0, 0.1],
        },
      },
      circle: {
        paint: {
          'circle-color': '#f04141',
          'circle-radius': 12,
        },
      },
    },
    touristicContentLayersProperties: {
      visibility: 'visible',
      icon: {
        layout: {
          'icon-image': ['concat', 'touristicContent', ['get', 'category']],
          'icon-size': 1,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
        },
      },
    },
  },
  treksByStep: 4,
  containsFilterShouldUseSelectAbove: 7,
  colSize: 12,
};
