export const fr = {
  toolbar: {
    orderTitle: 'Trier par',
    orderByAlphabet: 'Ordre alphabétique',
    orderByLocation: 'Proximité',
    orderByRandom: 'Aléatoire',
  },
  tabbar: {
    treks: 'Randonnées',
  },
  filters: {
    title: 'Filtres',
    erase: 'Effacer tout',
    results: 'Afficher les {{nb}} randonnées',
    oneresult: 'Afficher la randonnée',
    zeroresult: 'Aucune randonnée ne correspond à ces filtres',
    confirmButton: 'Ok',
    cancelButton: 'Annuler',
    selectPlaceholder: 'Sélectionner des',
    searchPlaceholder: 'Rechercher',
  },
  mapTreks: {
    treksAlert: {
      title: 'Choisir une randonnée',
      confirmButton: 'Ok',
      cancelButton: 'Annuler',
      informUser:
        'Plusieurs randonnées sont disponibles depuis ce point de départ',
    },
  },
  mapTrek: {
    poisAlert: {
      confirmButton: 'Ok',
      cancelButton: 'Annuler',
    },
  },
  trek: {
    downloadAlert: {
      contentTrek:
        "Télécharger cette randonnée afin d'y avoir accès hors connexion",
      confirmButton: 'Télécharger',
      cancelButton: 'Annuler',
      success: 'La randonnée est disponible hors ligne',
      error: 'Une erreur est survenue lors du téléchargement',
      confirmError: 'Ok',
      loading: 'Téléchargement en cours ...',
      firstDownload:
        "L'application stocke d'autres données nécessaires car c'est la première fois que vous téléchargez une randonnée. Vos prochains téléchargements seront moins volumineux.",
    },
    details: {
      city: 'Commune :',
      departure: 'Départ',
      arrival: 'Arrivée',
      departureArrival: 'Départ / Arrivée',
      practice: 'Pratiques',
      theme: 'Thématiques',
      description: 'Description',
      information: 'Informations pratiques',
      parking: 'Parking conseillé',
      publicTransport: 'Transports publics',
      trekDeparture: 'Départ de la balade',
      howToGoBack: 'Comment repartir',
      advice: 'Recommandations',
      features: 'Caractéristiques',
      access: { name: "Comment s'y rendre" },
      poi: {
        name: "Points d'intérêts",
      },
      touristicContent: {
        name: 'Contenus touristiques',
      },
      touristicEvents: {
        name: 'Événements touristiques',
      },
      informationDesks: { name: "Lieux d'information", website: 'Site web' },
      services: { name: 'Services' },
      infrastructures: { name: 'Infrastructures' },
      contact: 'Contact',
    },
  },
  treks: {
    emptyOnlineTreks: "Aucune randonnée n'est disponible selon vos critères",
    emptyOfflineTreks:
      "Aucune randonnée n'est disponible hors ligne selon vos critères",
    departure: 'Départ',
    numberOftrek: 'Nous avons trouvé 1 itinéraire.',
    numberOftreks: 'Nous avons trouvé {{nb}} itinéraires.',
    itinerary: 'Bonne balade !',
  },
  geolocate: {
    error: {
      header: 'Géolocalisation impossible',
      subHeader:
        "L'application rencontre un problème pour récupérer votre position.",
      message:
        "Veuillez vérifier que votre GPS est actif et que vous avez bien autorisé l'application à l'utiliser.",
      confirmButton: 'OK',
    },
  },
  error: {
    contentTrek:
      'Veuillez vous assurer d’être connecté afin d’accéder à la randonnée non téléchargée',
    reload: 'Recharger',
  },
  trekCard: {
    deleteTitle: 'Suppression',
    deleteLabel: 'Supprimer',
    askDelete: 'Supprimer cette randonnée du mode hors ligne ?',
    deletionInProgress: 'Suppression en cours',
    trekIsDelete: 'La randonnée est supprimée du mode hors ligne',
    errorWhileDeleting:
      'Une erreur est survenue lors de la suppression de la randonnée',
    cancelButton: 'Annuler',
    confirmButton: 'Ok',
  },
  inAppDisclosure: {
    title: 'Utilisation de votre localisation',
    description:
      "Nous allons vous demander la permission d'utiliser votre localisation.",
    geolocateFeatures: "L'application utilise votre localisation afin de :",
    geolocateForMap: 'Pouvoir vous repérer sur les cartes',
    geolocateForSort: 'Trier la liste des randonnées par proximité',
    geolocateForNavigate:
      "Toujours centrer la carte d'une randonnée sur votre localisation",
    dataUsage: 'Nous ne collectons aucune donnée de localisation.',
    displayRequest: 'Afficher la demande',
  },
};
