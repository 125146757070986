import { NgModule, APP_INITIALIZER } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Observable, of } from 'rxjs';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { SettingsService } from '@app/services/settings/settings.service';
import { fr } from './fr';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(): Observable<any> {
    return of(fr);
  }
}

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'app' }),
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    IonicModule.forRoot({
      mode: 'md',
      animated: true,
      rippleEffect: true,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    ServiceWorkerModule.register('sw-map.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    HttpClientModule,
    AppRoutingModule,
    NoopAnimationsModule,
    NgxMatomoTrackerModule.forRoot({
      disabled: !environment.useMatomo,
      trackerUrl: 'https://makinacorpus.matomo.cloud/',
      siteId: '10',
      scriptUrl: 'https://cdn.matomo.cloud/makinacorpus.matomo.cloud/matomo.js',
    }),
    NgxMatomoRouterModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: (settingsService: SettingsService) => () =>
        settingsService.initializeApp(),
      deps: [SettingsService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
